<template>
  <router-view v-if="!refresh" />
  <router-loading v-else />
</template>

<script>
import RouterLoading from "../../../base/components/Default/RouterLoading";
import debounce from 'lodash/debounce'
export default {
  components: {RouterLoading},
  computed: {
    //是否刷新路由页
    refresh() {
      return this.$store.getters.refresh;
    },
  },
  methods:{
    //结束刷新
    offRefresh() {
      this.$store.commit("setRefresh", false);
    },
  },
  created() {
    //防抖动
    this.offRefresh = debounce(this.offRefresh, 300);
  },
  watch: {
    refresh(val) {
      if (val) {
        this.offRefresh();
      }
    }
  }
}
</script>

<style>

</style>
